import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./LaunchTable.css";
import "../informationCards/Card.css";
import { fetchNewLaunchInfo } from "../data/launches";
import { fetchRockets, fetchRocketFamilies } from "../data/rocketFamilies";
import { fetchPads } from "../data/pads";
import { fetchSpacecraft, fetchSpacecraftFamilies } from "../data/spacecraft";
import { fetchOrbits } from "../data/orbits";
import { fetchOperators } from "../data/operators";
import { getPhotoLocation, formatTime, numberWithCommas, toTitleCase } from "../utils";
import StageAndRecoveryCard from "../informationCards/StageAndRecoveryCard";
import { Link } from "react-router-dom";
const LaunchInformation = () => {
  const { id } = useParams(); // get launch id from URL
  const [launchInfo, setLaunchInfo] = useState({});
  const [rockets, setRockets] = useState([]);
  const [spacecraft, setSpacecraft] = useState([]);
  const [orbits, setOrbits] = useState([]);
  const [spacecraftFamilies, setSpacecraftFamilies] = useState([]);
  const [rocketFamilies, setRocketFamilies] = useState([]);
  const [pads, setPads] = useState([]);
  const [operators, setOperators] = useState([]);
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          launchData,
          rocketData,
          padData,
          rocketFamilyData,
          spacecraftData,
          spacecraftFamilyData,
          orbitData,
          operatorData,
        ] = await Promise.all([
          fetchNewLaunchInfo(id),
          fetchRockets(),
          fetchPads("", {}),
          fetchRocketFamilies(),
          fetchSpacecraft("", {}, ""),
          fetchSpacecraftFamilies(),
          fetchOrbits(),
          fetchOperators(),
        ]);
        setLaunchInfo(launchData);
        setRockets(rocketData);
        setPads(padData);
        setRocketFamilies(rocketFamilyData);
        setSpacecraft(spacecraftData.spacecraft);
        setSpacecraftFamilies(spacecraftFamilyData);
        setOrbits(orbitData);
        setOperators(operatorData);
      } catch (err) {
        setError(err.message); // Set error message if fetching fails
      }
    };
    fetchData();
  }, [id]);

  // check to make sure launch exists
  if (error) {
    return <div style={{ textAlign: "center", color: "white" }}>{error}</div>;
  }

  // have loading indicator
  if (!launchInfo || !Object.keys(launchInfo).length) {
    return <div className="loading-indicator"></div>;
  }

  const rocketOnLaunch = rockets.find((rocket) => rocket.id === launchInfo.rocket);
  let spacecraftOnLaunch = null;
  let spacecraftFamilyOnLaunch = null;
  if (launchInfo.spacecraft_on_launch.length !== 0) {
    spacecraftOnLaunch = spacecraft.find(
      (spacecraft) => spacecraft.id === launchInfo.spacecraft_on_launch[0].spacecraft
    );
    spacecraftFamilyOnLaunch = spacecraftFamilies.find((family) => family.id === spacecraftOnLaunch.family);
  }
  const padOnLaunch = pads.find((pad) => pad.id === launchInfo.pad);
  const orbitOnLaunch = orbits.find((orbit) => orbit.id === launchInfo.orbit);
  const rocketFamilyOnLaunch = rocketFamilies.find((family) => family.id === rocketOnLaunch.family);
  const operatorOnLaunch = operators.find((operator) => operator.id === rocketFamilyOnLaunch.provider);
  const launchInFuture = new Date(launchInfo.time) > new Date();

  return (
    <div className="max-w-6xl mx-auto page-container-launch-page">
      <h1 className="centered-heading">{launchInfo.name}</h1>
      {/* General Launch Section */}
      <div className="launch-card launch-width">
        <div className="launch-info">
          <p>
            <b>Liftoff Time: </b>
            {formatTime(launchInfo.time.slice(0, -1))}
          </p>
          <p>
            <b>Customer: </b>
            {launchInfo.customer}
          </p>
          <p>
            <b>Orbit: </b>
            {orbitOnLaunch.name}
          </p>
          <p>
            <b>Payload Mass: </b>
            {launchInfo.mass !== null
              ? `${numberWithCommas(launchInfo.mass)} kg (${numberWithCommas(Math.round(launchInfo.mass * 2.2))} lb)`
              : "Unknown"}
          </p>
          {launchInfo.significant_stats.length !== 0 ? (
            <>
              <b style={{ color: "#b0b0c0" }}>Key Stats:</b>
              {launchInfo.significant_stats.map((stat, index) => {
                const [boldText, ...rest] = stat.split(" ");
                const remainingText = rest.join(" ");

                return (
                  <p key={index} id={stat}>
                    <strong>- {boldText}</strong> {remainingText}
                  </p>
                );
              })}
            </>
          ) : null}
        </div>
      </div>

      {/* Pad Section */}
      <h1 className="subheading subheading-margin">Pad</h1>
      <Link to={`/pad/${padOnLaunch.id}`} className="launch-width">
        <div className="launch-card">
          <div
            className="launch-image"
            style={{
              backgroundImage: `url(${getPhotoLocation()}${padOnLaunch.image.replace("http://127.0.0.1:8000/", "")})`,
            }}
          ></div>
          <div className="launch-info">
            <h1 style={{ margin: "0" }}>{padOnLaunch.name}</h1>
            <p>
              <b>Nickname: </b>
              {padOnLaunch.nickname}
            </p>
            <p>
              <b>Location: </b>
              {padOnLaunch.location}
            </p>
            <p>
              <b>Turnaround: </b>
              {launchInfo.pad_turnaround}
            </p>
            {launchInfo.launch_pad_stats.map(([important, stat], index) => {
              const [boldText, ...rest] = stat.split(" ");
              const remainingText = rest.join(" ");

              return (
                <p key={index} id={stat}>
                  <strong>{boldText}</strong> {remainingText}
                </p>
              );
            })}
          </div>
        </div>
      </Link>

      {/* Stage and recovery section */}
      {launchInfo.stage_and_recoveries.length !== 0 && (
        <h2 className="subheading subheading-margin">
          {launchInfo.stage_and_recoveries.length > 1 ? "Stage and Recoveries" : "Stage and Recovery"}
        </h2>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          alignItems: "center",
          width: "100%",
          marginTop: "-2px",
          marginBottom: "10px",
        }}
      >
        {launchInfo.stage_and_recoveries.map((stageAndRecovery) => (
          <StageAndRecoveryCard
            stageAndRecoveryData={stageAndRecovery}
            launchInFuture={launchInFuture}
            key={stageAndRecovery.id}
          />
        ))}
      </div>

      {spacecraftOnLaunch && (
        <>
          <h1 className="subheading subheading-margin">Spacecraft</h1>
          <Link to={`/spacecraft/${spacecraftOnLaunch.id}`} className="launch-width">
            <div className="launch-card">
              <div
                className="launch-image"
                style={{
                  backgroundImage: `url(${getPhotoLocation()}${spacecraftOnLaunch.image.replace(
                    "http://127.0.0.1:8000/",
                    ""
                  )})`,
                }}
              ></div>
              <div className="launch-info">
                <h1 style={{ margin: "0" }}>
                  {spacecraftOnLaunch.name}-{launchInfo.spacecraft_on_launch[0].num_flights}
                </h1>
                <p>
                  <b>Version: </b>
                  {toTitleCase(spacecraftOnLaunch.type)} {spacecraftFamilyOnLaunch.name} {spacecraftOnLaunch.version}
                </p>
                {spacecraftOnLaunch.nickname ? (
                  <p>
                    <b>Nickname: </b>
                    {spacecraftOnLaunch.nickname}
                  </p>
                ) : null}
                <p>
                  <b>Turnaround: </b>
                  {launchInfo.spacecraft_on_launch[0].spacecraft_turnaround}
                </p>
                {launchInfo.spacecraft_on_launch[0].spacecraft_stats.map(([important, stat], index) => {
                  const [boldText, ...rest] = stat.split(" ");
                  const remainingText = rest.join(" ");

                  return (
                    <p key={index} id={stat}>
                      <strong>{boldText}</strong> {remainingText}
                    </p>
                  );
                })}
              </div>
            </div>
          </Link>
        </>
      )}

      {/*Rocket section*/}
      <h1 className="subheading subheading-margin">Rocket</h1>
      <Link to={`/${rocketFamilyOnLaunch.name.toLowerCase()}/stats`} className="launch-width">
        <div className="launch-card">
          <div
            className="launch-image"
            style={{
              backgroundImage: `url(${getPhotoLocation()}${rocketOnLaunch.image.replace(
                "http://127.0.0.1:8000/",
                ""
              )})`,
            }}
          ></div>
          <div className="launch-info">
            <h1 style={{ margin: "0" }}>{rocketOnLaunch.name}</h1>
            {launchInfo.rocket_stats.map(([important, stat], index) => {
              const [boldText, ...rest] = stat.split(" ");
              const remainingText = rest.join(" ");

              return (
                <p key={index} id={stat}>
                  <strong>{boldText}</strong> {remainingText}
                </p>
              );
            })}
          </div>
        </div>
      </Link>

      {/* Launch Provider */}
      <h1 className="subheading subheading-margin">Launch Provider</h1>
      <div className="launch-card launch-width">
        <div
          className="launch-image"
          style={{
            backgroundImage: `url(${getPhotoLocation()}${operatorOnLaunch.image.replace(
              "http://127.0.0.1:8000/",
              ""
            )})`,
          }}
        ></div>
        <div className="launch-info">
          <h1 style={{ margin: "0" }}>{operatorOnLaunch.name}</h1>
          <p>
            <b>Company Turnaround: </b>
            {launchInfo.company_turnaround}
          </p>
          {launchInfo.launch_provider_stats.map(([important, stat], index) => {
            const [boldText, ...rest] = stat.split(" ");
            const remainingText = rest.join(" ");

            return (
              <p key={index} id={stat}>
                <strong>{boldText}</strong> {remainingText}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LaunchInformation;
