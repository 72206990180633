import { getHostName } from "../utils";

export async function fetchSpacecraftFamilies() {
  const data = await fetch(`${getHostName()}/api/spacecraftfamilies/?format=json`);
  return await data.json();
}

export async function fetchSpacecraft(query, filters, family) {
  const jsonString = encodeURIComponent(JSON.stringify(filters));
  const data = await fetch(
    `${getHostName()}/api/spacecraft/?format=json&filter=${jsonString}&query=${query}&family=${family}`
  );
  return await data.json();
}

export async function fetchSpacecraftInfo(id, startDate) {
  const data = await fetch(
    `${getHostName()}/api/spacecraftinformation/?id=${id}${
      startDate ? `&startdate=${JSON.stringify(startDate.toISOString())}` : ""
    }&format=json`
  );

  if (!data.ok) {
    throw new Error("Spacecraft Not Found");
  }

  return await data.json();
}

export async function fetchSpacecraftFamilyInfo(family, startDate) {
  const data = await fetch(
    `${getHostName()}/api/spacecraftfamilyinformation/?family=${family}${
      startDate ? `&startdate=${JSON.stringify(startDate.toISOString())}` : ""
    }&format=json`
  );

  return await data.json();
}
